<template>
  <div class="px-6 pt-7">
    <div class="mb-2.5 text-center text-2xl text-black font-700">
      {{ t('partners') }}
    </div>
    <van-grid class="az-grid" :column-num="3" :border="false" clickable>
      <template v-if="!commonData.brandInfo?.length">
        <van-grid-item v-for="(_, index) in Array.from({ length: 9 })" :key="index">
          <div class="h-11 w-full rounded-md bg-#F6F6F6"></div>
        </van-grid-item>
      </template>
      <template v-else>
        <van-grid-item v-for="value in commonData.brandInfo" :key="value">
          <div class="h-11 w-full flex items-center justify-center rounded-md bg-#F6F6F6 p-2.5">
            <van-image :src="value">
              <template #loading>
                <div class="h-11 w-full rounded-md bg-#F6F6F6"></div>
              </template>
            </van-image>
          </div>
        </van-grid-item>
      </template>
    </van-grid>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const store = useCommonStore()
const { commonData } = storeToRefs(store)
</script>

<i18n lang="json">
{
  "en": {
    "partners": "Trusted by 40+ partners"
  }
}
</i18n>

<style>
:root:root {
  --van-grid-item-content-active-color: none;
}

.az-grid .van-grid-item__content {
    padding: 5px;
}
</style>
